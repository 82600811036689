var divs = document.querySelectorAll('.home-body-part__link img'), i;

for (i = 0; i < divs.length; ++i) {
  divs[i].addEventListener("mouseover", function( event ) {
    event.target.dataset.src = event.target.getAttribute('src');
    event.target.src = event.target.dataset.hover;
  });

  divs[i].addEventListener("mouseout", function( event ) {
    var src = event.target.dataset.src;
    event.target.src = src;
  });
}


